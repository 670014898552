import React from 'react';
import "./messagebox.css";

export default class MessageBox extends React.Component {

    // Message Area; Layer 30+
    render() {
        return (
            <div className="container-fluid message-area">                
                <div className="container">
                    <div className="alert alert-success alert-dismissible fade show mx-auto" role="alert" style={{position:"relative", display: (this.props.type == "success" && this.props.display) ? "block" : "none" }}>
                        <h4 className="alert-heading">Success.</h4>
                        <span id="text">{this.props.text}</span>
                        <button type="button" className="close" onClick={this.props.onClose} aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>

                    <div className="alert alert-danger alert-dismissible fade show mx-auto" role="alert" style={{position:"relative", display: (this.props.type == "error" && this.props.display) ? "block" : "none" }}>
                        <h4 className="alert-heading">Nope.</h4>
                        <span id="text">{this.props.text}</span>
                        <button type="button" className="close" onClick={this.props.onClose} aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                </div>	
            </div>
        );
    }
}