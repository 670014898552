import React from 'react';
import "./sidepanel.css";

export default class Sidepanel extends React.Component {

    render() {        
        return (
            <div id="sidepanel-wrapper">
                <Screendimmer   onClick={this.props.onClose} 
                                active={this.props.open} />

                <div    className="sidepanel" 
                        style={{
                            width: this.props.open ? "25vw" : "0vw",
                            left: this.props.open ? "75%" : "100%"
                            }}>
                    
                    <button type="button" 
                        className="btn btn-link closebtn"
                        onClick={this.props.onClose}>
                        
                        &times;
                    </button>

                    <div class="container-fluid no-padding sidepanel-inline text-light">
                        <div class="jumbotron jumbotron-fluid bgcolor-transp">
                            
                            {this.props.children}

                        </div>
                    </div>   
                </div>
            </div>
        );
    }
}

class Screendimmer extends React.Component {

    render() {
        return (
          <div className="screen-dimmer" onClick={this.props.onClick} style={{display: this.props.active ? "block" : "none"}}></div>
        );
    }
}