import React from 'react';
import './App.css';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGamepad, faCode, faDollarSign } from "@fortawesome/free-solid-svg-icons";
import { faTwitch } from "@fortawesome/free-brands-svg-icons";

import Section1Background from "./assets/images/keyboard-chroma.jpg";
import Parallax1Background from "./assets/images/mouse_chroma.jpg";
import Parallax2Background from "./assets/images/code-2.jpg";

import ReactTwitchEmbedVideo from "react-twitch-embed-video"

import { Section, Header, Footer } from "./components/layout";

import Title from "./components/title/title";
import Navbar from './components/navbar/navbar';
import Sidepanel from "./components/sidepanel";
import Link from "./components/link/link";
import Parallax from './components/parallax/parallax';
import Projects from "./components/project/projects";
import Team from "./components/team/team";
import MessageBox from "./components/messagebox/messagebox"

import { LoginForm, CreateAccountForm } from "./components/forms";

import ContentText from "./assets/content-text.json";

import Auth from "./Auth";
import UserProfile from "./UserProfile";

class App extends React.Component {

  constructor(){
    super();

    this.state = {
      sidepanel: false,
      sidepanelForm: 1, // 1: Login, 2: Create
      isAuthenticated: false,
      messageBox: {
        text: "",
        type: "",
        display: false
      }
    }
  }

  async authenticate(){

    if(await Auth.Token())
      this.setState({isAuthenticated: true});
  }

  async componentDidMount(){

    // Try to authenticate user from storage
    await this.authenticate();
  }

  render(){
    return (
      <div className="App">
        
        {/* Sidepanel */}
        <Sidepanel  open={this.state.sidepanel}                      
                    onClose={ (() => { this.setState({sidepanel:false}) }).bind(this) }>
          
          {/* Forms */}
          <div id="forms" style={{display: this.state.isAuthenticated ? "none" : "block"}}>

            <LoginForm  style={{display: this.state.sidepanelForm == 1 ? "block" : "none"}}
                        onSwitchPanel={ (() => { this.setState({sidepanelForm: 2}) }).bind(this) } 
                        onSuccess={this.authenticate.bind(this, true)} 
                        onError={ (() => { this.setState({ messageBox: { text: "Login currently not available.", type: "error", display: true } }) }).bind(this) } />

            <CreateAccountForm  style={{display: this.state.sidepanelForm == 2 ? "block" : "none"}}
                                onSwitchPanel={ (() => { this.setState({sidepanelForm: 1}) }).bind(this) } 
                                onError={ (() => { this.setState({ messageBox: { text: "Registration currently not available.", type: "error", display: true } }) }).bind(this) }/>

          </div>

          {/* Profile */}
          <div style={{display: this.state.isAuthenticated ? "block" : "none"}}>
            <p className="lead">{UserProfile.FirstName()} {UserProfile.LastName()}</p>
          </div>
        </Sidepanel>    


        {/* ******************************************************************************************************** */}                

        {/* Content - Wrapper makes Content slideable for sidepanel */}
        <div  className="content-wrapper" 
              style={{left: this.state.sidepanel ? "-25%" : "0"}}>
          {/* ------------------------- Head ------------------------- */}
          <Header color="light" 
                  style={{backgroundImage:"url(" + Section1Background + ")"}}>
  
            {/* Title */}         
            <div id="section1-child-no-navbar" className="container" style={{height:"92vh", display: "block"}}>
              <div style={{float:"right", textAlign:"right", marginTop:"30vh"}}>
                <Title bgColor="primary" fontColor="light">Derp</Title><br/>
                <Title bgColor="light" fontColor="dark">Technologies</Title>
              </div>
            </div>

            {/* Navigation */}
            <Navbar isAuthenticated={this.state.isAuthenticated} actionLogin={(() => { this.setState({"sidepanel": true}) }).bind(this)}/>

          </Header>
  
          {/* ------------------------- Section 1 Livestream ------------------------- */}
          <Section  scrollHook="s1" 
                    color="white" 
                    headline={ContentText.sections._1.title} 
                    size="4">
    
            <p>Our stream will provide you with 1080p entertainment all around the week. To see a detailed schedule please visit our channel
            
              <Link href="http://www.twitch.tv/obscht" color="secondary">
                <FontAwesomeIcon icon={faTwitch} size="1x"/>/obscht
              </Link>
                    
              <br /><br />
  
              Join us on Twitch for an exciting blend of gaming and creativity! Our channel is a hub for passionate gamers and creative minds, featuring thrilling gameplay and unique creative projects. Whether you’re here for epic gaming sessions or to spark your creativity with our artistic endeavors, there’s something for everyone. Follow us to stay entertained, inspired, and part of our awesome community!
            </p>
  
            <br /><br /><br />
  
  
            {/* 3 Icon Row */}
            <div className="row text-center">
              <div className="col-sm">
                <h2 className="text-success">
                  <FontAwesomeIcon icon={faGamepad} size="1x"/>
                </h2>
                <h2 style={{marginBottom:"40px"}}>{ContentText.sections._1.icons._1}</h2>
              </div>
              <div className="col-sm">
                <h2 className="text-success">
                  <FontAwesomeIcon icon={faCode} size="1x"/>
                </h2>
                <h2 style={{marginBottom:"45px"}}>{ContentText.sections._1.icons._2}</h2>
              </div>
              <div className="col-sm">
                <h2 className="text-success">
                  <FontAwesomeIcon icon={faDollarSign} size="1x"/>
                </h2>
                <h2>{ContentText.sections._1.icons._3}</h2>
              </div>
            </div>	
                  
            <br /><br /><br /><br />
            <hr className="my-4" />
            <p className="text-muted">{ContentText.sections._1.twitch_chat_disclaimer}</p><br />
  
            {/*<ReactTwitchEmbedVideo channel="obscht" width="100%"/>*/}
  
          </Section>
  
          <Parallax height="80vh" 
                    image={Parallax1Background} 
                    quoteText={ContentText.parallax._1.quote_text}
                    quoteFooter={ContentText.parallax._1.quote_footer}
                    quoteSource={ContentText.parallax._1.quote_source} />      
  
          {/* ------------------------- Section 2 Projects ------------------------- */}
          <Section  scrollHook="s2" 
                    color="white" 
                    size="4"
                    headline={ContentText.sections._2.title}>   
  
              <hr className="my-4" />
              
  
              <br /><br /><br />
  
              {/* Dynamic project-list */}
              <Projects />

              <br /><br /><br />
          </Section>
  
          <Parallax height="80vh" image={Parallax2Background} />
  
          {/* ------------------------- Section 3 Team ------------------------- */}
          <Section  scrollHook="s3" 
                    color="lightgray" 
                    headline={ContentText.sections._3.title}>
  
            {/* Das svg wird als Referenz verwendet, damit die generierten FontAwesome SVGs den gradient übernehmen */}
            <svg width="0" height="0">
              <radialGradient id="rg" r="150%" cx="30%" cy="107%">
                <stop stop-color="#fdf497" offset="0" />
                <stop stop-color="#fdf497" offset="0.05" />
                <stop stop-color="#fd5949" offset="0.45" />
                <stop stop-color="#d6249f" offset="0.6" />
                <stop stop-color="#285AEB" offset="0.9" />
              </radialGradient>
            </svg>
  
            <br /><br />
  
            <Team />
  
            <hr className="my-4" />
            <p className="lead">{ContentText.sections._3.footer}</p>
          
          </Section>

          {/* ------------------------- FOOTER ------------------------- */}
          <Footer />

          {/* ------------------------- MESSAGEBOX FIXED LAYER 30 ------------------------- */}
          <MessageBox 
                text={this.state.messageBox.text} 
                type={this.state.messageBox.type}
                display={this.state.messageBox.display} 
                onClose={ (() => { this.setState({ messageBox: { display: false } }) }).bind(this)}/>
  
        {/* Closing wrapper */}
        </div>
  
      {/* Closing App Container */}
      </div>
    );
  }
}

export default App;
