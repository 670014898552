import React from 'react';
import "./parallax.css";


export default class Parallax extends React.Component {
    SPEED = 2;

    style = null;
    parallax = React.createRef();

    constructor(props){
        super(props);

        this.style = {
            height: this.props.height,
            backgroundImage: "url(" + this.props.image + ")",
            backgroundSize: "cover",
            backgroundPosition: "center"
        }
    }

    componentDidMount() {

        window.addEventListener("scroll", this.HandleScroll.bind(this, this.parallax));
    }

    HandleScroll(obj) {
        var scrollHeight = window.pageYOffset;

        obj.current.style.backgroundPosition = "50%" + ((scrollHeight - obj.current.offsetTop) / 3 + "px");
    }

    render() {
        return (
            <div ref={this.parallax} style={this.style}>
                <div className="container-fluid parallax-dimmer">
                    <table className="table" style={{height:"100%"}}> 
                        <tr>
                            <td className="border-0 align-middle text-light">
                                <blockquote className="blockquote only-on-desk" style={{marginLeft:"5%"}}>
                                    <h1 className="mb-0 display-5">{this.props.quoteText}</h1>
                                    <footer className="blockquote-footer">{this.props.quoteFooter}<cite title="Source Title">{this.props.quoteSource}</cite></footer>
                                </blockquote>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        );
    }
}