import React from 'react';
import "./projects.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faClock, faGlobe, faDesktop, faGamepad } from "@fortawesome/free-solid-svg-icons";

export default class Project extends React.Component {

	constructor() {
		super();
		this.state = {
			projects: []
		}
	}

	componentDidMount() {
		var rowStyle = {
			paddingTop: "80px",
			paddingBottom: "80px"
		}

		fetch("/projects")
			.then(results => { return results.json() })
			.then(data => { 
				var counter = 0;
				var projects = data.map( (project) => {
					var statusIcon = (project.status === "released")
						? <FontAwesomeIcon icon={faCheck} size="1x" style={{color:"green"}}/>
						: <FontAwesomeIcon icon={faClock} size="1x" style={{color:"orange"}}/>;

					var link = (project.web.length > 0) 
						? [
							<br />,
							<a href={project.web} class="badge badge-secondary">Visit Website.</a>,
							<br />
						  ]
						: "";

					var icon = null;
					switch(project.icon){
						case 1: 
							icon = <FontAwesomeIcon icon={faGlobe} size="7x" style={{color:"white"}}/>
							break;
						case 2:
							icon = <FontAwesomeIcon icon={faGamepad} size="7x" style={{color:"white"}}/>
							break;
						case 3:
							icon = <FontAwesomeIcon icon={faDesktop} size="7x" style={{color:"white"}}/>
							break;
						default:
							icon = <FontAwesomeIcon icon={faGlobe} size="7x" style={{color:"white"}}/>
					}

					counter++;
					if(counter%2==0){
						return(		
							<div className="row">
								{/* TEXT LEFT */}
								<div className="col-6 text-justify project-info" style={rowStyle}>
									<div className="text-right">
										<h2>{project.name}</h2>
										<small className="card-subtitle mb-2 text-muted"> {statusIcon} {project.status} </small>
									</div>
									<br /><br />
									<p>{project.description}</p>
									<div className="text-right">
										{link}	
									</div>
								</div>
								<div className="col text-center bg-lightgray">
									<table className="icon-cell align-middle">
										<td>
											{icon}
										</td>
									</table>	
								</div>
							</div>
							
						)
					}else{
						return(	
							<div className="row" >
								{/* TEXT RIGHT */}	
								<div className="col bg-lightgray">
									<table className="icon-cell align-middle">
										<td>
											{icon}
										</td>
									</table>
								</div>
								<div className="col-6 text-justify project-info" style={rowStyle}>
									<div className="text-left">
										<h2>{project.name}</h2>
										<small className="card-subtitle mb-2 text-muted"> {statusIcon} {project.status} </small>  
									</div>
									<br /><br />
									<p>{project.description}</p>	
									<div className="text-left">
										{link}	
									</div>	
								</div>
							</div>
							
						)
					}
				});

				this.setState({projects: projects});
			});
	}



    render() {
        return (
			<div className="container software-portfolio">
				{this.state.projects}
			</div>
        );
    }
}