import React from 'react';

export default class CreateAccountForm extends React.Component {

    constructor(){
        super();

        this.state = {
            email: "",
            password: ""
        }
    }


    render() {    
        return (
            <div>
                {/* --------------- Create Account --------------- */}
                <div className='container create-new-account' style={this.props.style}>
                    <h1 className='display-3'>Create ...</h1>
                    <p className='lead'>... a new, free and awesome account.</p>
                    <br />

                    <form>
    
                        <input id='reg-uname' type='text' className='form-control border border-light' placeholder='Your name' aria-describedby='basic-addon1' required />
                        <small id='emailHelp' className='form-text text-muted'>e.g. Daenerys Stormborn of the House Targaryen, First of Her Name, the Unburnt, Queen of the Andals and the First Men [ ... ]</small>
                    
                        <br /><br />
                        
                        <input id='reg-email' type='text' className='form-control border border-light' placeholder='Email' aria-describedby='basic-addon1' required />
                        <small className='form-text text-muted'>We'll never share your email with anyone else.</small>

                        <br /><br />
                        
                        <input id='reg-password-1' type='password' onkeyup='account.validatePasswords(this, document.getElementById(\"reg-password-2\"));' className='form-control border border-light' id='exampleInputPassword1' placeholder='Password' required />

                        <br />
                        
                        <input id='reg-password-2' type='password' onkeyup='account.validatePasswords(document.getElementById(\"reg-password-1\"), this);' className='form-control border border-light' id='exampleInputPassword1' placeholder='Again' required />
                        <small className='form-text text-muted'>You should use a password, that is save enough to secure your personal data.</small>
                        
                        <br />
                        
                        <div className='form-check'>
                            <label className='form-check-label'>
                                <input id='reg-tou' class='form-check-input' type='checkbox' value='false' required />
                                <p>I've red and accept the <a href='/termsofuse' target='_blank'>terms of use</a>.</p>
                            </label>
                        </div>
                    
                        <button type='button' onClick={this.props.onSwitchPanel} className='btn btn-info float-left' style={{marginRight:"10px", marginBottom:"10px"}}>Nope</button>
                        <button type='button' onClick={this.props.onError} className='btn btn-success float-left'>Create Account</button> 
                    </form>             
                </div> 
            </div>
        );
    }
}