import React from 'react';
import "./title.css";

export default class Title extends React.Component {
    render() {
        return (
            <h1 className="display-3 title-margin">
                <span className= { "title-inline bg-" + this.props.bgColor + " text-" + this.props.fontColor}>{this.props.children}</span>
            </h1>
        );
    }
}