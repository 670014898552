import React from 'react'
class Termsofuse extends React.Component {
  render() {
    return (
        <div className="container">
            <div className="jumbotron jumbotron-fluid text-center bgcolor-transp">
                <div className="container">
                    <h1 className="display-3">Terms of Use</h1>
                    <p className="text-muted">In order to create an account for Derp Technologies use must agree all terms below.</p>
                    <hr className="my-4" />
                    
            
                    <div className="container-fluid">
                        <p>
                        §1<br />
                        Bevor der User den internen Mitgliederbereich der Derp Technologies-Website nutzen kann, hat er sich zunächst über die Website anzumelden.
                        </p>
                        <br />
                        
                        <p>
                        §2<br />
                        Der User verpflichtet sich, im Rahmen des Anmeldevorgangs seine personenbezogenen Daten vollständig und zutreffend einzugeben.
                        </p>
                        <br />
            
                        <p>
                        §3<br />
                        Ferner wählt der User während des Anmeldevorgangs ein Passwort, das er geheim halten und nicht an Dritte weitergeben wird. Auch Derp Technologies wird das vom User gewählte Passwort Dritten nicht offenbaren.
                        </p>
                        <br />
                        
                        <p>
                        §4<br />
                        Sollte es zum Datendiebstahl kommen, wird Derp Technologies für entstandene Schäden nicht aufkommen.
                        </p>
                        <br />
            
                        <p>
                        §5<br />
                        Jeder User darf sich nur ein Mal registrieren.
                        </p>
                        <br />
            
                        <p>
                        §6<br />
                        Die Freischaltung des Accounts geschieht erst nach Bestätigung der Emailadresse.
                        </p>
                        <br />
                        
                        <p>
                        §7<br />
                        Bei jedem Login des Users werden personenbezogene Daten des entsprechenden Users bis zum nächsten Login gespeichert.
                        Diese Daten dienen ausschließlich zum Schutz vor Missbrauch und werden unter keinen Umständen an Unbefugte weitergegeben.
                        </p>
                        <br />
                        
                        <p>
                        §8<br />
                        Derp Technologies behält sich das Recht vor, einen User ohne Vorwarnung zu sperren oder aus dem Datenbestand zu löschen.
                        </p>
                        <br />
                        
                        <p>
                        §9<br />
                        Sollte der User seinen Account und entsprechende Daten vollständig gelöscht haben wollen, ist dies schriftlich beim Website-Betreiber zu beantragen. Der Betreiber muss dem Antrag innerhalb zumutbarer Zeit nachkommen.
                        </p>
                        <br />
                    </div>
                </div>
            </div>
        </div>
    )
  }
}
export default Termsofuse