import React from 'react';
import "./navbar.css";
import ContentText from "../../assets/content-text.json";
import NavbarButton from './navbar-button';

import { Link as ScrollerLink } from 'react-scroll';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook } from "@fortawesome/free-brands-svg-icons";

import LoginIcon from "../../assets/images/login_icon.png";

import UserProfile from "../../UserProfile";
import { faWindowMinimize } from '@fortawesome/free-solid-svg-icons';


export default class Navbar extends React.Component {
    
    constructor(props){
        super(props);
        this.state = {
            extraMargin: 0 // to align relative Navbar to bottom of screen
        }
    }

    nav = React.createRef();
    ankerPosTop = 0;

    height = 54; /* Bootstrap Navbar height default 54px */
    scrollDuration = 500;

    componentDidMount() {
        this.CalculateAnkorPoints(); // Inital calculation

        // If scroll position is below anker => fix nav on top 
        window.addEventListener("scroll", this.HandleScroll.bind(this, this.nav));

        // Recalculate Ankorpoints on window resize
        window.addEventListener("resize", this.CalculateAnkorPoints.bind(this));
    }

    CalculateAnkorPoints() {
        this.setState({extraMargin: 0}); // Clear extraMargin before get actual offset

        // Docking anker
        this.ankerPosTop = document.getElementById("nav-docking-anker").offsetTop;

        // Margin to align docking anker to bottom of section
        // Needed because navbar has a fixed size and section size is 92vh
        // Maybe there is a better solution
        this.setState({extraMargin: window.innerHeight - this.ankerPosTop - this.height });
    }

    HandleScroll(obj) {
        var scrollHeight = window.pageYOffset;

        if(scrollHeight > this.ankerPosTop + this.state.extraMargin){
            obj.current.classList.add("nav-fixed");
        }else{
            obj.current.classList.remove("nav-fixed");
        }
    }

    GetLoginText(){
        if(this.props.isAuthenticated){
            return UserProfile.FirstName() + " " + UserProfile.LastName();
        }else{
            return ContentText.navbar.button_login;
        }
    }

    
    render() {
        return (
            
            <div id="nav-docking-anker" style={{marginTop: this.state.extraMargin + "px"}}>
            {/* navbar will dock/undock at nav-docking-anker */}

                <nav    ref={this.nav} 
                        id="navbar" 
                        className="navbar navbar-expand-sm navbar-dark bgcolor-transp-6-dark nav-absolute">

                    <button className="navbar-toggler rounded-0 border-0"  
                            type="button" 
                            data-toggle="collapse" 
                            data-target="#navbarSupportedContent" 
                            aria-controls="navbarSupportedContent" 
                            aria-expanded="false" 
                            aria-label="Toggle navigation"
                            style={{marginLeft:"-14px"}}>
                    
                        <span className="navbar-toggler-icon"></span>
                    </button>


                    <div className="collapse navbar-collapse" id="navbarSupportedContent">

                        {/* Buttons links */}
                        <ul className="navbar-nav mr-auto">

                            {/* Button 1 */}
                            <li className="nav-item">
                                <ScrollerLink   className="btn-scroller" 
                                                activeClass="active" 
                                                to="s1" 
                                                spy={true} 
                                                smooth={true} 
                                                offset={-this.height} 
                                                duration={this.scrollDuration}>

                                    {ContentText.navbar.button_sec1}
                                </ScrollerLink>
                            </li>

                            {/* Button 2 */}
                            <li className="nav-item">
                                <ScrollerLink   className="btn-scroller" 
                                                activeClass="active" 
                                                to="s2" 
                                                spy={true} 
                                                smooth={true} 
                                                offset={-this.height} 
                                                duration={this.scrollDuration}>
                                                    
                                    {ContentText.navbar.button_sec2}
                                </ScrollerLink>
                            </li>

                            {/* Button 3 */}
                            <li className="nav-item">
                                <ScrollerLink   className="btn-scroller" 
                                                activeClass="active" 
                                                to="s3" 
                                                spy={true} 
                                                smooth={true} 
                                                offset={-this.height} 
                                                duration={this.scrollDuration}>
                                                    
                                    {ContentText.navbar.button_sec3}
                                </ScrollerLink>                            
                            </li>
                        </ul>

                        {/* Buttons rechts only on PC */}
                        <form className="form-inline my-2 my-lg-0 only-on-desk">
                            
                            {/* Button Follow */}
                            <a  href="http://www.facebook.com/derptech" 
                                >
                                
                                <span className="lead text-light">
                                    Follow us on
                                </span>

                                <FontAwesomeIcon className="follow-icon" icon={faFacebook} size="2x"/>
                            </a>

                            {/* Button Login */}
                            <NavbarButton onClick={ this.props.actionLogin }>
                                <span className="lead text-light">
                                    {this.GetLoginText()}
                                </span>

                                <img src={LoginIcon} className="login-icon"></img>
                            </NavbarButton>
                        </form>
                    </div>

                    {/* Button Login only on Mobile */}
                    <a  id="mob-login" href="https://google.de" 
                        className="avatar-username only-on-mob">
                        
                        <span className="lead text-light" >Login</span>
                        
                    </a>
                </nav>
            </div>
        );
    }
}