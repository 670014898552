import React from 'react';
import { Element } from 'react-scroll';

import "./section.css";

export default class Section extends React.Component {

    render() {
        return (
            <Element name={this.props.scrollHook} className="element">
                <div    className={"container-fluid no-padding bg-" + this.props.color} 
                        style={{display: "inline-block"}}>
                    
                    

                        {/* Inner container */}
                        <div className="container section-margin text-center">
                            
                            {/* Headline */}
                            <div    className="jumbotron jumbotron-fluid bg-color-transp"
                                    style={{padding:"0px"}}>

                                <div className="container">
                                    <h1 className={"display-" + (this.props.size || "3")}>{this.props.headline}</h1>
                                </div>
                            </div>

                            {/* Content */}
                            {this.props.children} 
                        </div>
                </div>
            </Element> 
        );
    }
}