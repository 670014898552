import React from 'react';
import "./team.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram, faFacebook, faTwitter } from "@fortawesome/free-brands-svg-icons";

import ProfilePicture from "../../assets/images/profile_pic.jpg";

export default class Team extends React.Component {

    constructor(){
        super();
        this.state = {
            team: []
        }
    }

    buildSocialMedia(channel){
        var html = [];

        // Instagram
        if(channel.instagram.length > 0){
            html.push( 
                <a  href={"http://instagram.com/" + channel.instagram} 
                    target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon className="social-icon instagram-icon" icon={faInstagram} size="2x"/>
                </a> 
            );
        }

        // Facebook
        if(channel.facebook.length > 0){
            html.push( 
                <a  href={"http://facebook.com/" + channel.facebook} 
                    target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon className="social-icon facebook-icon" icon={faFacebook} size="2x"/>
                </a> 
            );
        }

        // Twitter
        if(channel.twitter.length > 0){
            html.push( 
                <a  href={"http://twitter.com/" + channel.twitter} 
                    target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon className="social-icon twitter-icon" icon={faTwitter} size="2x"/>
                </a> 
            );
        }

        return html;
    }

    componentDidMount(){
        fetch("/user/team")
			.then(results => { return results.json() })
			.then(data => { 
				var team = data.map( (user) => {

                    var alias = (user.alias.length > 0) ? "'" + user.alias + "'" : "";
					return(		
						<div className='card border-0 bg-color-transp mx-auto' style={{width: "20rem"}}>
                            <img src={ProfilePicture} alt='' className='rounded-circle img-thumbnail profile-pic' style={{margin:"auto"}} />
                            <div className='card-body'>
                            <h4 className='card-title'>{user.firstname} {alias} {user.lastname}</h4>
                                <p style={{lineHeight:"40px"}}>			
                                    {this.buildSocialMedia(user.socialmedia)}
                                </p>
                            </div>      
                        </div>
					)
				});

				this.setState({team: team});
			});
    }

    render() {
        return (
            <div className="card-deck">
                {this.state.team}
            </div>
        );
    }
}