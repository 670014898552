import React from 'react';

export default class LoginForm extends React.Component {

    constructor(){
        super();

        this.state = {
            email: "",
            password: ""
        }
    }

    Login(event){
        event.preventDefault();        

        fetch("/user/auth", {
            method: 'post',
            body: JSON.stringify({ 
                email: this.state.email, 
                password: this.state.password })
        })
        .then(results => { return results.json() })
        .then(data => { 
            
            if(data.code == 100){
                localStorage.setItem("user", JSON.stringify(data.user));
                this.props.onSuccess();
            }
            
        });
    }

    render() {    
        return (
            <div className='container login' style={this.props.style}>


                <h1 className='display-3'>Login</h1>
                <p className='lead'>You are obviously not logged in. Enter your account-information to solve this.</p>
                <br /><br />
            
                <form /*onSubmit={this.Login.bind(this)}*/>
                    
                    <input value={this.state.email} onChange={((e) => { this.setState({email: e.target.value}) }).bind(this)} type='email' className='form-control border border-light' placeholder='Email'/>

                    <br />

                    <input id='login-password' type='password' className='form-control border border-light' placeholder='Password' required />
                    <br />
                    <button type='button' onClick={this.props.onError} className='btn btn-success float-left' style={{marginRight:"10px",marginBottom:"10px"}}>Login</button>
                    <button type='button' onClick={this.props.onError} className='btn btn-outline-warning float-left'>Forgot password?</button>
                </form>
            
                <br /><br /><br /><br />
                <hr className='my-4 border-secondary' />
                <blockquote className='blockquote'>
                    <p className='mb-1'>'Sir, I do not have an account for this. Please help.'</p>
                    <footer className='blockquote-footer'>You <cite title='Source Title'>right now</cite></footer>
                </blockquote>
                <button type='button' onClick={this.props.onSwitchPanel} className='btn btn-info'>Create Account</button>
            </div>
        );
    }
}