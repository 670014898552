import React from 'react';
import "./footer.css";

export default class Footer extends React.Component {
    render() {
        return (
            <div className="container-fluid bg-dark text-light footer-padding">
                <div className="container text-center">
                    <p className="lead">Derp Technologies</p>
                    <p className="text-muted">© Copyright <a href="http://www.derptech.de">derptech.de</a> 2020. All right reserved.<br />
                    Icons made by <a href="https://www.flaticon.com/authors/vectors-market" title="Vectors Market">Vectors Market</a> from <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a> is licensed by <a href="http://creativecommons.org/licenses/by/3.0/" title="Creative Commons BY 3.0" target="_blank">CC 3.0 BY</a>

                    <br /><br />
                    
    
                    <a href="/imprint" className='link color-gray'>Imprint</a></p>
   
                </div>
            </div>
        );
    }
}



