import React from 'react';
import "./navbar.css";

export default class NavbarButton extends React.Component {

    render() {
        return (
            <button type="button" 
                    className="btn btn-link navbar-button"
                    onClick={this.props.onClick}>
                    
                {this.props.children}
            </button>
        );
    }
}