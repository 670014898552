import UserProfile from "./UserProfile";
import Axios from "axios";

var Auth = {

    Token: async function(){        
        const user = UserProfile.Get();
        
        if(user != null){

            var response = await Axios.post("/user/auth", {
                email: user.credentials.email, 
                access_token: user.credentials.access_token
            });

            if(response.data.code == 100){
                return true;
            }else{
                return false;
            }
        }else{
            // no user in storage
            return false;
        }
       
    }
  };
  
  export default Auth;