import React from 'react';
import "./header.css";

export default class Header extends React.Component {
    render() {
        return (
            <div className={"container-fluid header no-padding bg-" + this.props.color} style={this.props.style}>
                {this.props.children} 
            </div>
        );
    }
}