var UserProfile = {
    data: null,

    Get: function(){
        if(this.data == null){
            try{
                this.data = JSON.parse(localStorage.getItem("user"));
                
            }catch(e){};
        }
        
        return this.data;
    },

    FirstName: function(){
        try{ return this.Get().firstname }catch(e){ return "" }
    },

    LastName: function(){
        try{ return this.Get().lastname }catch(e){ return "" }
    }
  
   
  };
  
  export default UserProfile;